import React from "react";
import SidebarTypeController from "./SidebarTypeController";
import BodyContentRender from "./BodyContentRender";
import { parseContent } from '../helpers/city-pages'
 
const ContentWithSidebar = ({ contentblockID, data, isCityPageRequiresTextReplacement, replaceWithName}) => {
  let { bodycontent } = data;

  return (
    <section className="py-12 section-stripe-bg bg-gray-50" key={contentblockID}>
      <div className="flex flex-wrap px-4 md:px-6 container mx-auto">
        <div className="w-full md:w-2/3 md:pr-4 ">
          <BodyContentRender markdown={isCityPageRequiresTextReplacement ? parseContent(bodycontent, replaceWithName) : bodycontent} />
        </div>
        <div className="w-full md:w-1/3 md:pl-4">
          {data.contentwithsidebarsidebar &&
            data.contentwithsidebarsidebar.map((item, index) => {
              return <SidebarTypeController key={index} data={item} />;
            })}
        </div>
      </div>
    </section>
  );
};

export default ContentWithSidebar;
