import React, {useState, useEffect, useCallback} from "react";
import { StaticQuery, graphql } from "gatsby";
import SingleReview from './atoms/SingleReview'
import { useEmblaCarousel } from "embla-carousel/react";

function ReviewsSidebarCarousel({ frontmatter }) {
  const { outercontainercss, renderashtml, title } = frontmatter;


    const [viewportRef, embla] = useEmblaCarousel();
    const [prevBtnEnabled, setPrevBtnEnabled] = useState(false);
    const [nextBtnEnabled, setNextBtnEnabled] = useState(false);

    const scrollPrev = useCallback(() => embla && embla.scrollPrev(), [embla]);
    const scrollNext = useCallback(() => embla && embla.scrollNext(), [embla]);
    const onSelect = useCallback(() => {
      if (!embla) return;
      setPrevBtnEnabled(embla.canScrollPrev());
      setNextBtnEnabled(embla.canScrollNext());
    }, [embla]);

    useEffect(() => {
      if (!embla) return;
      embla.on("select", onSelect);
      onSelect();
    }, [embla, onSelect]);


  return (
    <StaticQuery
      query={graphql`
        query getReviews {
          allMarkdownRemark(
            sort: { order: DESC, fields: [frontmatter___date] }
            filter: { frontmatter: { contentTypeKey: { eq: "testimonial" } } }
          ) {
            edges {
              node {
                id
                frontmatter {
                  id
                  name
                  date
                  description
                  featuredreview
                  source
                  reviewername
                  reviewertype
                  reviewservicearea {
                    id
                    frontmatter {
                      featuredcity
                      id
                      title
                    }
                  }
                  userphoto {
                    childImageSharp {
                      fixed(width: 50, height: 50, quality: 100) {
                        ...GatsbyImageSharpFixed_withWebp
                      }
                    }
                  }

                }
              }
            }
          }
        }
      `}
      render={(data) => {
        let reviewsData = [...new Map(data.allMarkdownRemark.edges.map(item => [item.node.id, item])).values()]
        reviewsData = reviewsData.reverse();
        return (
          <div
            className={`rounded-lg mb-2 shadow bg-white ${outercontainercss}`}
          >





            <div className="px-6 pt-6">
              <h2 className="text-gray-800 font-bold text-2xl md:text-3xl">
                {title}
              </h2>
            </div>

            <div className="p-6">
            <div className="embla">
              <div className="embla__viewport" ref={viewportRef}>
                <div className="embla__container">
                  {reviewsData.map((item, index) => (
                    <div className="embla__slide" key={index}>
                      <div>
                          <SingleReview
                              key={index}
                              name={item.node.frontmatter.name}
                              description={item.node.frontmatter.description}
                              reviewername={item.node.frontmatter.reviewername}
                              reviewertype={item.node.frontmatter.reviewertype}
                              reviewercity={item.node.frontmatter.reviewservicearea.frontmatter.title}
                              userphoto={item.node.frontmatter.userphoto}
                              source={item.node.frontmatter.source}
                              featuredreview={item.node.frontmatter.featuredreview}
                            />
                      </div>
                    </div>
                  ))}
                </div>
              </div>

            </div>
            </div>
          </div>
        );
      }}
    />
  );
}

export default ReviewsSidebarCarousel;
