import React, { useState } from "react";
import Img from "gatsby-image";
import ContactFormModal from "./ContactFormModal";
import { parseContent } from '../helpers/city-pages'
 

const CallToAction = ({ contentblockID, data, isCityPageRequiresTextReplacement, replaceWithName }) => {
  const [contactFormOpen, setContactFormOpen] = useState(false);

  
  return (
    <section className="h-auto bg-cover relative">
      <Img
        fluid={data.image.childImageSharp.fluid}
        alt={isCityPageRequiresTextReplacement ? parseContent(data.title, replaceWithName) : data.title}
        className="cta-image absolute top-0 h-full"
      />
      <div
        className={`text-white container carousel-content-container relative mx-auto`}
      >
        <div className="py-16 px-4 sm:px-6 lg:py-24 lg:px-8 lg:flex lg:items-center lg:justify-between">
          <div className="md:max-w-lg lg:max-w-3xl">
            <h2 className="text-3xl font-extrabold tracking-tight text-white text-shadow md:text-4xl leading-9 mb-2">
              {isCityPageRequiresTextReplacement ? parseContent(data.title, replaceWithName) : data.title}
            </h2>
            <p className="text-white block">{isCityPageRequiresTextReplacement ? parseContent(data.description, replaceWithName) : data.description}</p>
          </div>
          <div className="mt-8 flex lg:mt-0 lg:flex-shrink-0">
            
              <button
                onClick={() => setContactFormOpen(true)}
                className="button-active-bhvr w-full block font-bold tracking-tight text-lg lg:text-xl cursor-pointer transition duration-150 ease-in-out bg-brand-600 focus:bg-brand-700 hover:bg-brand-700 focus:outline-none px-5 py-3 lg:py-4 lg:px-8 rounded-full text-white font-display"
              >
                Get Started
              </button>
             
          </div>
        </div>
      </div>

      <ContactFormModal
        modalOpen={contactFormOpen}
        onModalClose={setContactFormOpen}
      />
    </section>
  );
};

export default CallToAction;
