import React, { useState } from "react";
import Img from "gatsby-image";
import FsLightbox from "fslightbox-react";

function PhotoGallery({ title, description, images, data }) {

  let slidesLinks = []
  


  for(let i = 0; i<images.length;i++){
    if(images[i] && images[i].image) {
      slidesLinks.push(images[i].image.childImageSharp.fluid.src)
    }
  }
  const [lightboxController, setLightboxController] = useState({
    toggler: false,
    slide: 1,
  });

  function openLightboxOnSlide(number) {
    setLightboxController({
      toggler: !lightboxController.toggler,
      slide: number,
    });
  }

  return (
    <section className="py-12 section-stripe-bg">
      <div className="container mx-auto px-4 md:px-6">
      <FsLightbox
        toggler={lightboxController.toggler}
        slide={lightboxController.slide}
        showThumbsOnMount={true}
        sources={slidesLinks}
      />
      <h2 className="font-display text-2xl md:text-3xl font-semibold text-center">{title}</h2>
    <p className="text-gray-700 text-center max-w-3xl mx-auto">{description}</p>
      <div className="flex flex-wrap mt-6 justify-center">
        {data.photogallery.frontmatter.images.map((image, i) => {
          if(image && image.image) {
            return (
              <button key={i} onClick={() => openLightboxOnSlide(i + 1)} className="focus:outline-none p-2 w-1/2 md:w-1/3 lg:w-auto">
                <Img fixed={image.image.childImageSharp.fixed}  className="shadow hover:shadow-xl transition-shadow duration-300 ease-in-out overflow-hidden rounded-lg gallery-img-thumbs" />
              </button>
            ) 
          }
          
        })}
      </div>

      </div>
    </section>
  );
}

export default PhotoGallery;
