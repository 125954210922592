import React from "react";
import DynamicSidebarController from './DynamicSidebarController'

const Sidebar = ({ data }) => {
 
  const { frontmatter } = data;

  const {
    bodycontent,
    dynamicsidebarid,
    id,
    outercontainercss,
    renderashtml,
    title,
  } = frontmatter;
  

  return (
    dynamicsidebarid ? <DynamicSidebarController dynamicsidebarid={dynamicsidebarid} frontmatter={frontmatter}/> : <> <div
    data-sidebarid={id}
    className={`rounded-lg mb-2 shadow bg-white ${outercontainercss}`}
  >
    <div className="px-6 pt-6">
      <h2 className="text-gray-800 font-bold text-2xl md:text-3xl">{title}</h2>
    </div>
    {bodycontent && renderashtml ? (
      <div dangerouslySetInnerHTML={{ __html: bodycontent }} />
    ) : (
      <div className="p-6">{bodycontent}</div>
    )}
  </div></>
  ) 
 
};

export default Sidebar;
