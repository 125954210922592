import React from "react";
import PropTypes from "prop-types";
import PhotoGallery from "./PhotoGallery";
import ContentWithSidebar from "./ContentWithSidebar";
import ContentFullWidth from "./ContentFullWidth";
import CallToAction from "./CallToAction";
import DynamicReactSectionsController from './DynamicReactSectionsController'
import FooterLogoShowcase from './FooterLogoShowcase'
import { parseContent } from '../helpers/city-pages'

const MainContentBlockController = ({ contentblockID, data, isCityPageRequiresTextReplacement, replaceWithName }) => {
  switch (contentblockID) {
    case "photogallery":
      return (
        <PhotoGallery
          data={data}
          title={isCityPageRequiresTextReplacement ? parseContent(data.photogallery.frontmatter.title, replaceWithName) : data.photogallery.frontmatter.title}
          description={isCityPageRequiresTextReplacement ? parseContent(data.photogallery.frontmatter.description, replaceWithName) : data.photogallery.frontmatter.description}
          images={data.photogallery.frontmatter.images}
        />
      );
    case "contentwidthsidebar":
      return <ContentWithSidebar data={data} contentblockID={contentblockID} isCityPageRequiresTextReplacement={isCityPageRequiresTextReplacement} replaceWithName={replaceWithName}/>;
    case "contentfullwidth":
      return <ContentFullWidth data={data} contentblockID={contentblockID} isCityPageRequiresTextReplacement={isCityPageRequiresTextReplacement} replaceWithName={replaceWithName}/>;
    case "calltoactionsection":
      return <CallToAction data={data} isCityPageRequiresTextReplacement={isCityPageRequiresTextReplacement} replaceWithName={replaceWithName}/>;
    case "dynamicreactsection":
      return <DynamicReactSectionsController data={data} isCityPageRequiresTextReplacement={isCityPageRequiresTextReplacement} replaceWithName={replaceWithName}/>;
    case "logoshowcase":
      return <FooterLogoShowcase data={data} contentblockID={contentblockID} />
      default:
      return <div></div>;
  }
};


MainContentBlockController.propTypes = {
  contentblockID: PropTypes.string,
  isCityPageRequiresTextReplacement: PropTypes.bool,
  replaceWithName: PropTypes.string
}
MainContentBlockController.defaultProps = {
  isCityPageRequiresTextReplacement: false,
  replaceWithName: ""
}


export default MainContentBlockController;
