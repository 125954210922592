export const monthNames = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ]
  
  export const nth = function(d) {
    if (d > 3 && d < 21) return 'th'
    switch (d % 10) {
      case 1:
        return 'st'
      case 2:
        return 'nd'
      case 3:
        return 'rd'
      default:
        return 'th'
    }
  }

  export const dateAddDays = function(addDays) {
    const dateInXDays = new Date()
    dateInXDays.setDate(dateInXDays.getDate() + addDays)
    return dateInXDays
  }

  export const dateOutputInXDays = function(addDays) {
    return monthNames[dateAddDays(addDays).getUTCMonth() % 12] + ' ' + dateAddDays(addDays).getUTCDate() + nth(dateAddDays(addDays).getUTCDate())
  }
  