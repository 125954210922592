import React from 'react'
import ContactInfo from "../settings/contact_info.json";

const Star = (<svg className="w-6 h-6" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="#ffbc00">
<path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
</svg>)

const StarsRatingComponent = ({className, reviewavg, reviewscount, enabled, centerOnMobile}) => (
    enabled == false ? null : (
        <div className={`${className} ${centerOnMobile ? 'text-center md:text-left' : ''}`}>
      <div className={`flex ${centerOnMobile ? 'justify-center md:justify-start' : ''}`}>
         {Star}
         {Star}
         {Star}
         {Star}
         {Star}
      </div>
      <div>
      <span
          itemScope
          className="rating-desc font-semibold text-white mt-2 block"
          itemType="https://schema.org/Product"
        >
          <span itemProp="name">{ContactInfo.companyname}</span>
          <span> </span>
          <span
            itemScope
            itemProp="aggregateRating"
            itemType="https://schema.org/AggregateRating"
          >rated <span itemProp="ratingValue">{reviewavg}</span>/5 <br />
            based on <span itemProp="reviewCount">
              {reviewscount}
            </span>{' '}
            reviews.{' '}
          </span>
        </span>
      </div>
  </div>
    )
)

 
export default StarsRatingComponent
