export function parseContent(string, altCity) {
  if (string && altCity) {
    const newString = string.replace(/{{city}}/g, altCity ? altCity : '')
    const secondReplacement = newString.replace(
      /{{citySlug}}/g,
      (altCity ? altCity : '')
        .replace(/\./g, '')
        .toLowerCase()
        .replace(/\s/g, '-') + '-ca'
    )

    return secondReplacement
  } else {
      return string
  }
}