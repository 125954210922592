import React from "react";
import ReviewsSidebarCarousel from './ReviewsSidebarCarousel'
import FiftyOffWaterHeaterRepair from './coupons/FiftyOffWaterHeaterRepair'
import SidingOneThousandPromotion from './coupons/SidingOneThousandPromotion'
import Title24StartingPrice from "./coupons/Title24StartingPrice";

const DynamicSidebarController = ({ dynamicsidebarid, frontmatter }) => {


  switch (dynamicsidebarid) {
    case "reviews_carousel":
      return (
        <ReviewsSidebarCarousel frontmatter={frontmatter}/>
      );
    case "150OFFTANKLESSREPLACEMENTUPGRADE":
      return <SidingOneThousandPromotion/>
    case "50OFFWATERHEATERREPLACEMENT":
      return <FiftyOffWaterHeaterRepair/>
    case "TITLE24STARTINGPRICE":
      return <Title24StartingPrice/>
    default:
      return null;
  }


};

export default DynamicSidebarController;
