import React from "react"; 
import BodyContentRender from "./BodyContentRender";
import { parseContent } from '../helpers/city-pages'

const ContentFullWidth = ({ contentblockID, data,  isCityPageRequiresTextReplacement, replaceWithName}) => {
  let { bodycontent } = data;

   
  return (
    <section className="py-12 section-stripe-bg" key={contentblockID}>
      <div className="w-full px-4 md:px-6 container mx-auto ">
        {data && data.renderashtml ?  <div  dangerouslySetInnerHTML={{ __html:  parseContent(bodycontent, replaceWithName) }} /> : <BodyContentRender markdown={isCityPageRequiresTextReplacement ? parseContent(bodycontent, replaceWithName) : bodycontent} />}
      
         
      </div>
    </section>
  );
};

export default ContentFullWidth;
