import React from "react";
import ReviewTilesComponent from './ReviewTilesComponent' 
import BenefitsGrid from './BenefitsGrid'

const DynamicReactSectionsController = ({ data }) => {
  let moduleName = data && data.value 

  switch (moduleName) {
    case "reputationtiles":
      return (
        <ReviewTilesComponent/>
      ); 
    case "benefitsgrid":
      return (
        <BenefitsGrid/>
      );
    default:
      return null;
  }


};

export default DynamicReactSectionsController;
