import React from 'react'
import Img from "gatsby-image";

const Star = (<svg className="w-6 h-6 text-brand-500" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
<path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
</svg>)

function SingleReview({name, description, reviewername, reviewertype, reviewercity, userphoto, source, featuredreview}) {

    return <div>
        <div className="py-1 flex items-center">
            <div className="flex-shrink-0 rounded-full w-12 h-12 overflow-hidden">
           {userphoto ? <Img
                fixed={userphoto.childImageSharp.fixed}

                className="w-full object-cover"
              /> : null }
            </div>

            <div className="ml-4 leading-5">
            <span className="font-semibold text-gray-800 block text-lg">{reviewername}, {reviewertype}</span>
             <span className="block text-gray-700 capitalize">{reviewercity} {source ? ' (' + source + ')' : null}</span>
            </div>

        </div>


        <div className="flex items-center py-3">
            {Star}
            {Star}
            {Star}
            {Star}
            <span className="opacity-85">{Star}</span>
        </div>

        <div className="text-gray-800 font-bold pb-2">
            {name}
        </div>

        <div className="text-gray-700">
            {description}
        </div>
    </div>
}

export default SingleReview

