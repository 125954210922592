import React from "react";
import Sidebar from "./Sidebar";

const SidebarTypeController = ({ data }) => {
    //if single sidebar, return component with data
  if (data.sidebar) {
    return <Sidebar data={data && data.sidebar} />;
  } else if (data.sidebarcollection) {
    //if sidebarcollection is not null, map through sidebars array and return each sidebar component
    return (
      <>
        {data.sidebarcollection.frontmatter.sidebars.map((sidebar, sb) => {
          return <Sidebar key={sb} data={sidebar && sidebar.sidebar} />;
        })}
      </>
    );
  }
};

export default SidebarTypeController;
